import { Content } from '../../../common/api/graphql/getContentsGql';
import { DistributionPartnerUser } from '../../../common/api/graphql/getLoginUserGql';
import {
  QueryBulkGiftCardIssueConfig,
  QueryAPIConfidentialInformationGifteeBoxConfigs
} from '../../../common/api/graphql/getProjectsGql';
import { AppActionTypes } from '../actions/actionType';
import type {
  QueryProjectGiftDistributionChannel,
  QueryProjectAdvertisingMediumRegistration,
  QuerySolutionSystem,
  QueryProjectPurposeCategory,
  QueryMarketingIndustry,
  QueryGiftCardConfig,
  QueryMessageThread,
  QueryProjectUrlCode,
  QueryGiftCardTicketConfig,
  QueryMessageCardConfig,
  QueryHostCompany,
  QueryFixedIssueConfig,
  QueryProjectClaims,
  QueryApiGiftCardIssueConfig,
  QueryIssueCode,
  QueryApiAllowedIps,
  QueryAccessToken,
  QueryAPIConfidentialInformationGiftCardConfig,
  QueryAPIConfidentialInformationGifteeBoxConfig,
  QueryGifteeBoxConfig,
  QueryBulkGifteeBoxIssueConfig,
  QueryBulkGifteeBoxIssueConfigIssueAmountRequest,
  NULL_CONFIDENTIAL_INFORMATION,
  API_CONFIDENTIAL_INFORMATION,
  BULK_CONFIDENTIAL_INFORMATION
} from '../../../common/api/graphql/getProjectsGql';
import type { Project } from '../../../common/api/graphql/projectTypes';
import type { AppAction } from '../actions/actionType';
import type { GetProjectsQuery } from '@/src/common/api/graphql/getProjectsGql';
import type { ClaimEstimate } from '@common/api/graphql/getClaimEstimate';

// 案件詳細用に多量の情報を持つ Project の State 型定義
// TODO: projectDetail.ts reducer に移動
export type LocalProjects = LocalProject[];

export type LocalProject = Project<
  QueryProjectGiftDistributionChannel[],
  LocalSolutionSystem,
  LocalGiftCardConfig[],
  QueryProjectPurposeCategory,
  QueryMarketingIndustry
> & {
  isSample: boolean;
  messageThread: QueryMessageThread | null;
  urlCode: QueryProjectUrlCode;
  hostCompany: LocalHostCompany;
  claims: QueryProjectClaims;
  confidentialInformation: LocalConfidentialInformation;
  projectAdvertisingMediumRegistrations: QueryProjectAdvertisingMediumRegistration[];
  gifteeBoxConfigs: LocalGifteeBoxConfigs;
};

export type LocalSolutionSystem = Omit<
  QuerySolutionSystem,
  'solutionSystemIdentifier'
> & {
  solutionSystemIdentifier:
    | QuerySolutionSystem['solutionSystemIdentifier']
    | null;
};

export type SolutionSystemGcpSelected = {
  value: string;
};
export type LocalGifteeBoxConfigs = LocalGifteeBoxConfig[] | null;
export type LocalGifteeBoxConfig = Omit<
  QueryGifteeBoxConfig,
  'bulkGifteeBoxIssueConfigs'
> & {
  bulkGifteeBoxIssueConfigs: LocalBulkGifteeBoxIssueConfig[];
};

export type LocalBulkGifteeBoxIssueConfig = QueryBulkGifteeBoxIssueConfig & {
  isIssueAmountRequested: boolean;
  issueAmountRequest: LocalBulkGifteeBoxIssueConfigIssueAmountRequest;
  claimEstimate: LocalClaimEstimate;
};

export type LocalBulkGifteeBoxIssueConfigIssueAmountRequest =
  NonNullable<QueryBulkGifteeBoxIssueConfigIssueAmountRequest>;

export type LocalConfidentialInformation =
  | LocalNullConfidentialInformation
  | LocalBulkConfidentialInformation
  | LocalAPIConfidentialInformation;

export type LocalNullConfidentialInformation = {
  __typename: typeof NULL_CONFIDENTIAL_INFORMATION;
  reason: string;
};

export type LocalBulkConfidentialInformation = {
  __typename: typeof BULK_CONFIDENTIAL_INFORMATION;
  bulkDeliverables?: LocalBulkDeliverable[];
};

export type LocalAPIConfidentialInformation = {
  __typename: typeof API_CONFIDENTIAL_INFORMATION;
  apiAllowedIps: QueryApiAllowedIps;
  accessTokens: QueryAccessToken[];
  giftCardConfigs: LocalAPIConfidentialInformationGiftCardConfigs;
  gifteeBoxConfigs: LocalAPIConfidentialInformationGifteeBoxConfigs;
};

export type LocalAPIConfidentialInformationGiftCardConfig =
  QueryAPIConfidentialInformationGiftCardConfig & {
    issueCode: LocalIssueCode;
  };

type LocalIssueCode = QueryIssueCode;

type LocalAPIConfidentialInformationGiftCardConfigs =
  LocalAPIConfidentialInformationGiftCardConfig[];

export type LocalAPIConfidentialInformationGifteeBoxConfig =
  QueryAPIConfidentialInformationGifteeBoxConfig & {
    issueCode: LocalIssueCode;
  };

type LocalAPIConfidentialInformationGifteeBoxConfigs =
  LocalAPIConfidentialInformationGifteeBoxConfig[];

export type LocalIssueAmountRequest = {
  issueAmount: number;
  requestedAt: string;
};

type LocalHostCompany = QueryHostCompany;

export type LocalGiftCardConfig = {
  urlCode: string;
  unitPrice: number;
  giftWalletStorable: boolean;
  giftCardTicketConfigs: LocalGiftCardTicketConfig[];
  messageCardConfig: QueryMessageCardConfig | null;
  creativeCheckApproved: boolean;
  isIssuable: boolean;
  bulkGiftCardIssueConfigs: LocalBulkGiftCardIssueConfig[];
  apiGiftCardIssueConfigs: LocalApiGiftCardIssueConfig[];
  currentApiGiftCardIssueConfig: LocalApiGiftCardIssueConfig | null;
};

export type LocalGiftCardTicketConfig = QueryGiftCardTicketConfig;

export type LocalClaimEstimate = null | ClaimEstimate;

export type LocalBulkGiftCardIssueConfig = {
  fixedIssueConfig: QueryFixedIssueConfig;
  deliveryDate: string;
  issueAmountRequest: LocalIssueAmountRequest;
  requestedIssueAmount: number;
  urlCode: string;
  isIssueAmountRequested: boolean;
  claimEstimate: LocalClaimEstimate;
};

export type LocalBulkDeliverable =
  | LocalBulkGiftCardDeliverable
  | LocalBulkGifteeBoxDeliverable;

export type LocalBulkGiftCardDeliverable = {
  __typename: 'BulkGiftCardDeliverable';
  giftCardConfig: LocalBulkGiftCardDeliverableGiftCardConfig;
  unitPrice: number;
} & LocalBulkDeliverableBase;

export type LocalBulkGifteeBoxDeliverable = {
  __typename: 'BulkGifteeBoxDeliverable';
  gifteeBoxConfig: LocalBulkGifteeBoxDeliverableGifteeBoxConfig;
  initialPoint: number;
  exchangeableEndLabel: string;
} & LocalBulkDeliverableBase;

type LocalBulkGiftCardDeliverableGiftCardConfig = QueryGiftCardConfig;

type LocalBulkGifteeBoxDeliverableGifteeBoxConfig = QueryGifteeBoxConfig;

export type LocalBulkDeliverableBase = {
  urlCode: string;
  issueAmount: number;
  deliveredAt: string;
  downloadType: string;
};

export type LocalApiGiftCardIssueConfig = QueryApiGiftCardIssueConfig;

// ダッシュボード用に最小限の情報を持つ Project の State 型定義
export type ProjectsState = GetProjectsQuery['projects'];
export type ProjectState = ProjectsState[number];

const projects = (
  state: ProjectsState = [],
  action: AppAction
): ProjectsState => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECTS_SUCCESS:
      return action.projects;
    case AppActionTypes.CREATE_SAMPLE_PROJECT_SUCCESS:
      return [action.project, ...state];
    default:
      return state;
  }
};

export default projects;
