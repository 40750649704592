import { getUniqueObjects } from '@common/lib/getUniqueElements';
import { getNodesFromEdges } from '@common/lib/ikedayamaConnection';
import { AppActionTypes } from '@console/actions/actionType';
import { advertisingPeriod } from '@console/reducers/projectDetail/advertisingPeriod';
import { giftDistributionPeriod } from '@console/reducers/projectDetail/giftDistributionPeriod';
import { hostCompany } from '@console/reducers/projectDetail/hostCompany';
import { marketingIndustry } from '@console/reducers/projectDetail/marketingIndustry';
import { messageThread } from '@console/reducers/projectDetail/messageThread';
import { projectPurposeCategory } from '@console/reducers/projectDetail/projectPurposeCategory';
import { solutionSystem } from '@console/reducers/projectDetail/solutionSystem';
import { thirdPartyService } from '@console/reducers/projectDetail/thirdPartyService';
import { combineReducers } from 'redux';
import type { AppAction } from '@console/actions/actionType';
import type {
  LocalProject,
  LocalBulkDeliverable
} from '@console/reducers/projects';
import type { Reducer } from 'redux';

const name: Reducer<LocalProject['name'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.name;
    default:
      return state;
  }
};

const description: Reducer<LocalProject['description'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.description;
    default:
      return state;
  }
};

const urlCode: Reducer<LocalProject['urlCode'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.urlCode;
    default:
      return state;
  }
};

const isSample: Reducer<LocalProject['isSample'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.isSample;
    default:
      return state;
  }
};

const projectGiftDistributionChannels: Reducer<
  LocalProject['projectGiftDistributionChannels'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.projectGiftDistributionChannels;
    default:
      return state;
  }
};

const projectAdvertisingMediumRegistrations: Reducer<
  LocalProject['projectAdvertisingMediumRegistrations'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.projectAdvertisingMediumRegistrations;
    default:
      return state;
  }
};

const giftCardConfigs: Reducer<
  LocalProject['giftCardConfigs'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.giftCardConfigs;
    case AppActionTypes.UPDATE_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_ISSUE_AMOUNT:
      if (!state) {
        return state;
      }
      return state.map((giftCardConfig, giftCardConfigIndex) => {
        if (giftCardConfig.urlCode !== action.giftCardConfigUrlCode) {
          return giftCardConfig;
        }
        return {
          ...giftCardConfig,
          bulkGiftCardIssueConfigs: giftCardConfig.bulkGiftCardIssueConfigs.map(
            (bulkGiftCardIssueConfig, bulkGiftCardIssueConfigIndex) => {
              if (
                bulkGiftCardIssueConfigIndex !==
                action.bulkGiftCardIssueConfigIndex
              ) {
                return bulkGiftCardIssueConfig;
              }
              return {
                ...bulkGiftCardIssueConfig,
                issueAmountRequest: {
                  ...bulkGiftCardIssueConfig.issueAmountRequest,
                  issueAmount:
                    Number(action.issueAmount) < 1
                      ? 1
                      : Number(action.issueAmount)
                }
              };
            }
          )
        };
      });
    case AppActionTypes.GET_PROJECT_BULK_GIFT_CARD_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS:
      if (!state) {
        return state;
      }
      return state.map(giftCardConfig => {
        if (giftCardConfig.urlCode !== action.giftCardConfigUrlCode) {
          return giftCardConfig;
        }
        return {
          ...giftCardConfig,
          bulkGiftCardIssueConfigs: giftCardConfig.bulkGiftCardIssueConfigs.map(
            (bulkGiftCardIssueConfig, bulkGiftCardIssueConfigIndex) => {
              if (
                bulkGiftCardIssueConfigIndex !==
                action.bulkGiftCardIssueConfigIndex
              ) {
                return bulkGiftCardIssueConfig;
              }
              return {
                ...bulkGiftCardIssueConfig,
                claimEstimate: action.claimEstimate
              };
            }
          )
        };
      });
    default:
      return state;
  }
};

const claims: Reducer<LocalProject['claims'] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.claims;
    default:
      return state;
  }
};

const confidentialInformation: Reducer<
  LocalProject['confidentialInformation'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.confidentialInformation;
    default:
      return state;
  }
};

const bulkDeliverables: Reducer<LocalBulkDeliverable[] | null, AppAction> = (
  state = null,
  action
) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_INITIAL_BULK_DELIVERABLES_SUCCESS:
    case AppActionTypes.GET_PROJECT_CONTINUOUS_BULK_DELIVERABLES_SUCCESS:
      if (!action.bulkDeliverableConnection) return state;
      const next = getNodesFromEdges<LocalBulkDeliverable>(
        action.bulkDeliverableConnection.edges
      );
      if (state === null) {
        return next;
      } else {
        return getUniqueObjects<LocalBulkDeliverable>(
          [...state, ...next],
          (bulkDeliverable1, bulkDeliverables2) =>
            bulkDeliverable1.urlCode === bulkDeliverables2.urlCode
        );
      }
    case AppActionTypes.EXIT_PROJECT_DETAIL:
      return null;
    default:
      return state;
  }
};

const gifteeBoxConfigs: Reducer<
  LocalProject['gifteeBoxConfigs'] | null,
  AppAction
> = (state = null, action) => {
  switch (action.type) {
    case AppActionTypes.GET_PROJECT_DETAIL_SUCCESS:
      return action.project.gifteeBoxConfigs;
    case AppActionTypes.UPDATE_PROJECT_BULK_GIFTEE_BOX_ISSUE_CONFIG_ISSUE_AMOUNT:
      if (!state) {
        return state;
      }
      return state.map(gifteeBoxConfig => {
        if (gifteeBoxConfig.urlCode !== action.gifteeBoxConfigUrlCode) {
          return gifteeBoxConfig;
        }
        return {
          ...gifteeBoxConfig,
          bulkGifteeBoxIssueConfigs:
            gifteeBoxConfig.bulkGifteeBoxIssueConfigs.map(
              bulkGifteeBoxIssueConfig => {
                if (
                  bulkGifteeBoxIssueConfig.urlCode !==
                  action.bulkGifteeBoxIssueConfigUrlCode
                ) {
                  return bulkGifteeBoxIssueConfig;
                }
                return {
                  ...bulkGifteeBoxIssueConfig,
                  issueAmountRequest: {
                    ...bulkGifteeBoxIssueConfig.issueAmountRequest,
                    issueAmount:
                      Number(action.issueAmount) < 1
                        ? 1
                        : Number(action.issueAmount)
                  }
                };
              }
            )
        };
      });
    case AppActionTypes.GET_BULK_GIFTEE_BOX_ISSUE_CONFIG_CLAIM_ESTIMATE_SUCCESS:
      if (!state) {
        return state;
      }
      return state.map(gifteeBoxConfig => {
        if (gifteeBoxConfig.urlCode !== action.gifteeBoxConfigUrlCode) {
          return gifteeBoxConfig;
        }
        return {
          ...gifteeBoxConfig,
          bulkGifteeBoxIssueConfigs:
            gifteeBoxConfig.bulkGifteeBoxIssueConfigs.map(
              bulkGifteeBoxIssueConfig => {
                if (
                  bulkGifteeBoxIssueConfig.urlCode !==
                  action.bulkGifteeBoxIssueConfigUrlCode
                ) {
                  return bulkGifteeBoxIssueConfig;
                }
                return {
                  ...bulkGifteeBoxIssueConfig,
                  claimEstimate: action.claimEstimate
                };
              }
            )
        };
      });
    default:
      return state;
  }
};

export const projectDetail = combineReducers({
  name,
  description,
  urlCode,
  isSample,
  hostCompany,
  advertisingPeriod,
  giftDistributionPeriod,
  solutionSystem,
  marketingIndustry,
  projectPurposeCategory,
  projectGiftDistributionChannels,
  projectAdvertisingMediumRegistrations,
  thirdPartyService,
  messageThread,
  giftCardConfigs,
  claims,
  confidentialInformation,
  bulkDeliverables,
  gifteeBoxConfigs
});
